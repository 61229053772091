exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-all-categories-page-js": () => import("./../../../src/templates/AllCategoriesPage.js" /* webpackChunkName: "component---src-templates-all-categories-page-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/ContactPage.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-gallery-page-js": () => import("./../../../src/templates/GalleryPage.js" /* webpackChunkName: "component---src-templates-gallery-page-js" */),
  "component---src-templates-privacy-page-js": () => import("./../../../src/templates/PrivacyPage.js" /* webpackChunkName: "component---src-templates-privacy-page-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-services-page-js": () => import("./../../../src/templates/ServicesPage.js" /* webpackChunkName: "component---src-templates-services-page-js" */)
}

